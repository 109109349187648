.swipeButtons{
  position: fixed;
  bottom: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.swipeButtons .MuiIconButton-root{
  background-color: #fff;
  box-shadow: 0px 10px 53px 0px rgb(0,0,0,0.3) !important;
}