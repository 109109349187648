.card{
  position: relative;
  background-color: #fff;
  width: 50vw;
  height: 50vh;
  max-width: 80vw;
  box-shadow: 0 10px 53px 0px rgba(0,0,0,0.3);
  border-radius: 20px;
  background-size:cover;
  background-position: center;
}
.card:hover{
  cursor:move;
}
.card h3{
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}
.cards__cardContainer{
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}
.swipe{
  position: absolute;
}